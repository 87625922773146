@import '../styles/dark.scss';

div.tw-loading {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $app-bg;

    img.tw-gear {
        position: fixed;
        left: calc(50% - 75px);
        top: calc(50% - 75px);
    }
}