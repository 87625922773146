$style-name: 'dark';

$default: #616160;
$primary: #3e82df;

$app-bg: #161616;

$panel-bg: #303030;
$panel-radius: 4px;
$panel-focus: #666;
$panel-title-bg: #353535;
$panel-title-txt: #fff;

$accent: #EDCE37;

$market-up: #089981;
$market-down: #F23645;

$font-list: 'Titillium Web', -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;