@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/utilities/api';

@import './styles/dark.scss';

div.tw--approot {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    min-width: 700px;
    background-color: $app-bg;
    font-family: $font-list;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 13px;
}

img.logo {
    position: fixed;
    top: 5px;
    left: 5px;
}

div {
    box-sizing: border-box;
}

div.curtain {
    background-color: rgba(#000, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}